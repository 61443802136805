<template>
  <component :is="tag" v-bind="props">
    <span
      :class="['propvision-ring', { 'propvision-ring--loading': loading }]"
      :style="{
        '--ring-size': ringSize,
        '--inner-ring-size': innerRingSize,
      }"
    >
      <slot />
    </span>
  </component>
</template>

<script setup lang="ts">
import { defineProps } from "vue"

const props = defineProps<{
  tag: string | object
  props?: Record<string, any>
  loading?: boolean
  ringSize?: string
  innerRingSize?: string
}>()

// Set default values
const ringSize = props.ringSize ?? "1em"
const innerRingSize = props.innerRingSize ?? "0.6em"
</script>

<style scoped>
.propvision-ring {
  display: inline-block;
  width: var(--ring-size, 1em);
  height: var(--ring-size, 1em);
  border-radius: 50%;
  background: var(--ps-ai-gradient-conic);
  position: relative;
  text-decoration: none;
  transition: transform 0.3s ease-in-out;
}

.propvision-ring::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--inner-ring-size, 0.8em);
  height: var(--inner-ring-size, 0.8em);
  background: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.propvision-ring:hover, .propvision-ring--loading {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
