<template>
  <nice-tooltip>
    <template #content>
      <span v-html="$t('integrations.extension-propvision.generic.documents.generatingInformation', {
      duration: $t('integrations.extension-propvision.reel.estimatedDuration'),
      })"/>

      <ul class="mt-2">
        <li v-for="context in runningVideoOrders">
          <span v-html="$t('integrations.extension-propvision.generic.documents.generatingVideoInformation', {
              name: getBrokerName(context.userId),
              when: formatFromNow(context.createdTimestamp),
            })" />
        </li>
      </ul>
    </template>
    <div
      class="inline-flex flex-nowrap shrink-0 items-center space-x-2 whitespace-nowrap"
      v-if="runningVideoOrders?.length"
    >
      <spinner />
      <GradientFlashText>
        {{ $t("integrations.extension-propvision.generic.documents.generatingVideosCount", runningVideoOrders.length) }}
      </GradientFlashText>
    </div>
  </nice-tooltip>
</template>
<script setup lang="ts">
import GradientFlashText from "@/components/integrations/propvision/GradientFlashText.vue"
import { IntegrationOrderPropvision } from "@/integrations/types/integration-propvision"
import { NiceTooltip } from "@nice-ui"
import { defineProps } from "vue"
import { useBroker } from "@/composables/use-broker"
import { timestampToDate } from "@/utils/formatters/date"
import moment from "moment"
const { getBrokerById } = useBroker()

const { runningVideoOrders = [] } = defineProps<{ runningVideoOrders: IntegrationOrderPropvision[] }>()

const getBrokerName = (brokerIdString: string) => {
  const brokerId = parseInt(brokerIdString)
  const broker = getBrokerById(brokerId)
  return broker?.name ?? "Unbekannt"
}

const formatFromNow = (timestamp: number) => {
  const date = timestampToDate(timestamp)
  return moment(date).fromNow()
}
</script>
