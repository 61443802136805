<script setup lang="ts">
import { ExtensionPropvision, Integration } from "@/integrations/types/integration-service"
import { IntegrationMapping } from "@/integrations/types/integration-page"
import { computed, onMounted, onUnmounted, ref } from "vue"
import { useI18n } from "vue-i18n"
import { usePusher } from "@/plugins/useRealtime"
import { usePropvisionExtension } from "@/components/integrations/propvision/usePropvisionExtension"
import useEnvironment from "@/plugins/use-environment"
import PropvisionRing from "@/components/integrations/propvision/PropvisionRing.vue";

const props = defineProps<{
  integration: Integration
  mapping: IntegrationMapping<ExtensionPropvision>
  locale: string
}>()

const { t } = useI18n()
const pusher = usePusher()

const { orders, extension: _1, refetch } = usePropvisionExtension()
const { isDevelopment, isStaging } = useEnvironment()

onMounted(() => {
  pusher.on(`propvision_operation_failed`, refetchThisIntegration)
  pusher.on(`propvision_operation_success`, refetchThisIntegration)
})

onUnmounted(() => {
  pusher.off(`propvision_operation_failed`)
  pusher.off(`propvision_operation_success`)
})

const getPercentage = (num, total) => (!total || !num ? 0 : Math.round((num / total) * 100))

const contingent = computed(() => props.integration?.contingent)
const details = computed(() => contingent.value?.details)
const debug = computed(() => isDevelopment.value || isStaging.value)

const isRefetching = ref(false)

const refetchThisIntegration = () => {
  isRefetching.value = true
  refetch()
  isRefetching.value = false
}
</script>

<template>
  <div
    class="w-[300px] disabled-hover relative flex flex-col border border-gray-300 p-3 rounded text-left mb-3 max-w-72"
  >
    <div class="flex justify-center items-center w-full py-4">
      <PropvisionRing tag="span" class="mt-[-8px] mr-1" />
      <h3 class="text-lg font-bold">{{ t("integrations.extension-propvision.name") }}</h3>
      <nice-tooltip :content="t('integrations.extension-propvision.widget.helpTooltip')">
        <a class="ml-2 text-primary" :href="t('integrations.extension-propvision.widget.helpLink')" target="_blank">
          <fa-icon name="question-circle" />
        </a>
      </nice-tooltip>
    </div>
    <div class="mt-1" v-for="(quota, operation) in details">
      {{ t(`integrations.extension-propvision.${operation}.label`) }}
      <nice-progress :stroke-width="4" :percentage="getPercentage(quota.used, quota.total)" subtype="quotaUsage">
        {{ `${quota.used}/${quota.total}` }}
      </nice-progress>
    </div>

    <div class="mt-2" v-if="debug && orders?.totalCount">
      <div class="mt-2">{{ contingent?.used }} of {{ contingent?.total }} used (~ {{ orders.totalCount }} running)</div>
    </div>
  </div>
</template>
