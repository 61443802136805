<template>
  <div>
    <GradientBorderButton @click="videoCompositorOpen = true" class="mr-2">
      <fa-icon name="video"></fa-icon>
      {{ t("integrations.extension-propvision.reel.action") }}
    </GradientBorderButton>
    <PropvisionDialogVideoReel
      operation="reel"
      :visible="videoCompositorOpen"
      :images="images"
      @update:visible="videoCompositorOpen = $event"
      :onSubmit="handleOperationSubmit"
      @complete="handleOperationSubmitComplete"
    />
  </div>
</template>

<script setup lang="ts">
import { ImageType } from "@/components/media/media.types"
import {computed, ref, watch} from "vue"
import GradientBorderButton from "../GradientBorderButton.vue"
import { usePropvisionExtension } from "../usePropvisionExtension"
import PropvisionDialogVideoReel from "./PropvisionDialogVideoReel.vue"
import { createVideoOperationOrderData } from "../propvision.utils"
import useShop from "@/plugins/use-shop"
import { useBroker } from "@/composables/use-broker"
import { get } from "@vueuse/core"
import { useI18n } from "vue-i18n"
import {useTeams} from "@/composables/useTeams";
import useCore from "@/plugins/use-core";
import {Broker} from "@/interfaces/db";

const { t } = useI18n()

export type SubmitVideoOperationParams = {
  operation: "reel"
  images: ImageType[]
  config: any
}

export type SubmitVideoOperationResultParams = {
  operation: "reel"
  images: ImageType[]
  data: any
  result: any
}

const {
  images = [],
  resourceId,
  resourceType,
} = defineProps<{ images: ImageType[]; resourceId: number; resourceType: "project" | "property" }>()

const emit = defineEmits(["submitted-video-operation"])
const { shop } = useShop()
const { broker: currentBroker } = useBroker()
const { graphql } = useCore()
const { dispatchOperation } = usePropvisionExtension()
const { getTeam } = useTeams()
const team = computed(() => getTeam(get(currentBroker)?.teamId))

const videoCompositorOpen = ref(false)

const assignedBroker = ref<Broker | null>(null)

const getResourceBrokerId = async () => {
  const query = `query {
    ${resourceType}(id: ${resourceId}) {
       broker {
          id
          name
          email
          phone
          avatarUrl
        }
      }
    }`

  try {
    const response = await graphql(query)
    assignedBroker.value = response?.[resourceType]?.broker || null
  } catch(error) {
    assignedBroker.value = null
  }
}

watch(() => resourceId, async (newResourceId) => {
  if (newResourceId) {
    await getResourceBrokerId()
  }
}, {immediate: true})

const handleOperationSubmit = async ({ operation, images, config }: SubmitVideoOperationParams) => {
  if (!resourceId || !resourceType) {
    throw new Error("projectId or propertyId must be provided")
  }

  const website = get(team)?.website ?? get(shop)?.homepage ?? ""
  const logo_url = get(team)?.logoUrl ?? get(shop)?.logoUrl ?? ""
  const broker_id = get(currentBroker)?.id


  const assignee = get(config)?.usePropertyBroker && get(assignedBroker) ? {
    image_url: get(assignedBroker)?.avatarUrl,
    name: get(assignedBroker)?.name,
    email: get(assignedBroker)?.email,
    phone: get(assignedBroker)?.phone,
  } : null;

  const { usePropertyBroker, ...cleanedConfig} = config;
  const newConfig = { ...cleanedConfig, website, logo_url, broker_id, assignee }

  const data = createVideoOperationOrderData(images, newConfig, operation, {
    resource_id: resourceId,
    resource_type: resourceType,
    video_name: config.headline,
  })

  const result = await dispatchOperation(data, operation)

  // emit something to allow consumers to update state (temp placeholder) with info
  videoCompositorOpen.value = false

  return { operation, images, data, result }
}

const handleOperationSubmitComplete = async ({ operation, images, data, result }: SubmitVideoOperationResultParams) => {
  // receives the result from the form dialog complete
  emit("submitted-video-operation", { operation, images, data, result })
}
</script>
